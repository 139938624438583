export const ProseA = () => import('./../../components/content/ProseA.vue')
export const ProseH1 = () => import('./../../components/content/ProseH1.vue')
export const ProseH2 = () => import('./../../components/content/ProseH2.vue')
export const ProseH3 = () => import('./../../components/content/ProseH3.vue')
export const ProseImg = () => import('./../../components/content/ProseImg.vue')
export const AppCommentInput = () => import('./../../components/AppCommentInput.vue')
export const AppLoginDialog = () => import('./../../components/AppLoginDialog.vue')
export const AppMenu = () => import('./../../components/AppMenu.vue')
export const AppMenuBar = () => import('./../../components/AppMenuBar.vue')
export const AppMsgFlow = () => import('./../../components/AppMsgFlow.vue')
export const AppNavBar = () => import('./../../components/AppNavBar.vue')
export const AppOverflowContent = () => import('./../../components/AppOverflowContent.vue')
export const AppRegisterDialog = () => import('./../../components/AppRegisterDialog.vue')
export const CommentViewPanel = () => import('./../../components/CommentViewPanel.vue')
export const MemoPanel = () => import('./../../components/MemoPanel.vue')
export const PagePanel = () => import('./../../components/PagePanel.vue')
export const SubCommentsViewPanel = () => import('./../../components/SubCommentsViewPanel.vue')
export const UserAvatar = () => import('./../../components/UserAvatar.vue')
export const SkeletonCard = () => import('./../../components/skeleton/Card.vue')
export const SkeletonPage = () => import('./../../components/skeleton/Page.vue')
export const NuxtWelcome = () => import('./../../node_modules/nuxt/dist/app/components/welcome.vue')
export const NuxtLayout = () => import('./../../node_modules/nuxt/dist/app/components/nuxt-layout')
export const NuxtErrorBoundary = () => import('./../../node_modules/nuxt/dist/app/components/nuxt-error-boundary')
export const ClientOnly = () => import('./../../node_modules/nuxt/dist/app/components/client-only')
export const DevOnly = () => import('./../../node_modules/nuxt/dist/app/components/dev-only')
export const ServerPlaceholder = () => import('./../../node_modules/nuxt/dist/app/components/server-placeholder')
export const NuxtLink = () => import('./../../node_modules/nuxt/dist/app/components/nuxt-link')
export const NuxtLoadingIndicator = () => import('./../../node_modules/nuxt/dist/app/components/nuxt-loading-indicator')
export const NuxtRouteAnnouncer = () => import('./../../node_modules/nuxt/dist/app/components/nuxt-route-announcer')
export const NuxtImg = () => import('./../../node_modules/@nuxt/image/dist/runtime/components/NuxtImg.vue')
export const NuxtPicture = () => import('./../../node_modules/@nuxt/image/dist/runtime/components/NuxtPicture.vue')
export const ContentRenderer = () => import('./../../node_modules/@nuxt/content/dist/runtime/components/ContentRenderer.vue')
export const MDC = () => import('./../../node_modules/@nuxtjs/mdc/dist/runtime/components/MDC.vue')
export const MDCRenderer = () => import('./../../node_modules/@nuxtjs/mdc/dist/runtime/components/MDCRenderer.vue')
export const MDCSlot = () => import('./../../node_modules/@nuxtjs/mdc/dist/runtime/components/MDCSlot.vue')
export const ColorScheme = () => import('./../../node_modules/@nuxtjs/color-mode/dist/runtime/component.vue3.vue')
export const NuxtPage = () => import('./../../node_modules/nuxt/dist/pages/runtime/page')
export const NoScript = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Link = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Base = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Title = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Meta = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Style = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Head = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Html = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const Body = () => import('./../../node_modules/nuxt/dist/head/runtime/components')
export const NuxtIsland = () => import('./../../node_modules/nuxt/dist/app/components/nuxt-island')
export const globalComponents: string[] = ["Mtag","ProseBlockquote","ProseCode","ProseEm","ProseH4","ProseH5","ProseH6","ProseHr","ProseLi","ProseOl","ProseP","ProsePre","ProseScript","ProseStrong","ProseTable","ProseTbody","ProseTd","ProseTh","ProseThead","ProseTr","ProseUl","Icon"]
export const localComponents: string[] = ["ProseA","ProseH1","ProseH2","ProseH3","ProseImg","AppCommentInput","AppLoginDialog","AppMenu","AppMenuBar","AppMsgFlow","AppNavBar","AppOverflowContent","AppRegisterDialog","CommentViewPanel","MemoPanel","PagePanel","SubCommentsViewPanel","UserAvatar","SkeletonCard","SkeletonPage","NuxtWelcome","NuxtLayout","NuxtErrorBoundary","ClientOnly","DevOnly","ServerPlaceholder","NuxtLink","NuxtLoadingIndicator","NuxtRouteAnnouncer","NuxtImg","NuxtPicture","ContentRenderer","MDC","MDCRenderer","MDCSlot","ColorScheme","NuxtPage","NoScript","Link","Base","Title","Meta","Style","Head","Html","Body","NuxtIsland"]